import { Location, LocationStrategy, PathLocationStrategy, NgIf, isPlatformBrowser } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Inject,
	OnDestroy,
	OnInit,
	PLATFORM_ID
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { convertToParamMap, ParamMap, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatestWith, filter, Subscription } from 'rxjs';
import { authHardLogin } from '../../../core/auth/auth.actions';
import { AuthLoginState } from '../../../core/auth/auth.models';
import { selectAuthLoginState } from '../../../core/auth/auth.selectors';
import { LogService } from '../../../core/log/log.service';
import { navigateHome } from '../../../core/router/routes.actions';
import { selectSessionID } from '../../../core/session/session.selectors';
import { selectUserOperationState } from '../../../domain/user/user.selectors';
import { DNOperationState } from '../../util/util';
import { DottnetDialogComponent } from '../dn-dialog.component';
import { PayloadSignin, SigninFormComponent } from './signin-form/signin-form.component';
import { TranslateModule } from '@ngx-translate/core';
import { SpinnerComponent } from '../../spinner/spinner.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatButtonModule } from '@angular/material/button';
import { closeDialog } from '../dn-dialog.actions';

export interface SigninDialogData {
	dialogTitle?: string;
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
    selector: 'dottnet-dialog-signin',
    templateUrl: 'signin-dialog.component.html',
    styleUrls: ['signin-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogModule, MatButtonModule, FontAwesomeModule, NgIf, SpinnerComponent, SigninFormComponent, TranslateModule]
})
export class SigninDialogComponent
	extends DottnetDialogComponent<SigninDialogComponent>
	implements OnInit, OnDestroy
{
	isSubmitting: boolean = false;

	// Subs
	authStateSub: Subscription;
	userOperationSub: Subscription;
	dialogTitle: string;
  url: string;
  oldSessionID: number;

	constructor(
		dialogRef: MatDialogRef<SigninDialogComponent>,
		store: Store,
		@Inject(MAT_DIALOG_DATA) private dialogData: SigninDialogData,
		private router: Router,
		private changeDetectorRef: ChangeDetectorRef,
		private logService: LogService,
		@Inject(PLATFORM_ID) platformId: Object
	) {
		super(dialogRef, undefined, store, platformId);
	}

	ngOnInit(): void {
		this.dialogTitle = this.dialogData.dialogTitle;
		// emetto evento di loggato quando cambia l'authstate
		this.authStateSub = this.store
			.select(selectAuthLoginState)
			.pipe(
				filter((authLoginState) => authLoginState === AuthLoginState.LOGGEDHARD),
				combineLatestWith(this.store.select(selectSessionID))
			)
			.subscribe(([_, oldSessionID]) => {
				this.logService.info(
					'Creating session after login from signin dialog. Old session: ',
					oldSessionID
				);

				this.url = this.router.url;
        this.oldSessionID = oldSessionID;

				super.hardedLoginOk();
				if (this.router.url.includes('signup')) {
					this.store.dispatch(navigateHome());
				}
			});

		this.userOperationSub = this.store.select(selectUserOperationState).subscribe((userOp) => {
			if (DNOperationState.LOADING === userOp) this.isSubmitting = true;
			else this.isSubmitting = false;
			this.changeDetectorRef.detectChanges();
		});
	}

	closeDialog() {
		// super.onExit();
    this.store.dispatch(closeDialog());
	}

	ngOnDestroy(): void {
		if (this.authStateSub) this.authStateSub.unsubscribe();
		if (this.userOperationSub) this.userOperationSub.unsubscribe();
	}

	onPost(payloadSignin: PayloadSignin) {
    let referrer: string = '';
		if (isPlatformBrowser(this.platformId)) {
			referrer = document.referrer;
		}
		const par: Params = <Params>{};
		const params: ParamMap = convertToParamMap(par);
    const oldSessionID = this.oldSessionID;
    const url = this.url;
		this.store.dispatch(authHardLogin({payloadSignin, params, oldSessionID, url, referrer}));
	}
}
