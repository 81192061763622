<div mat-dialog-title class="flex-row flex-justify-space-between flex-align-center desktop-dialog">
	<img id="dialogLogoDN" (click)="goToContext()" [src]="infoLogoDn" [alt]="'logo'" />
	<div class="dialog-title">{{ dialogTitle || 'dottnet.menu.accedi' | translate }}</div>
	<button mat-icon-button (click)="goToContext()">
		<fa-icon class="dn-icon" [icon]="getCrossIcon()"></fa-icon>
	</button>
</div>
<div mat-dialog-title class="flex-row flex-justify-space-between flex-align-center mobile-dialog">
	<button mat-icon-button (click)="goToContext()">
		<fa-icon class="dn-icon" [icon]="getChevronLeftIcon()"></fa-icon>
	</button>
	<div class="dialog-title">{{ dialogTitle || 'dottnet.menu.accedi' | translate }}</div>
	<img id="dialogLogoDN" (click)="goToContext()" [src]="infoLogoDn" [alt]="'logo'" />
</div>

<hr class="dialog-title-separator" />

<mat-dialog-content class="flex-col flex-justify-center flex-align-center">
	<dottnet-spinner class="form-spinner" *ngIf="isSubmitting"></dottnet-spinner>
	<dottnet-form-signin
		(formEventEmitter)="onPost($event)"
		(closeDialogEmitter)="closeDialog()"
	></dottnet-form-signin>
</mat-dialog-content>
